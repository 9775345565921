export default {
  homepageHero: {
    height: ['100vh', '', '80vh'],
    // marginBottom: ['2rem', '', '2rem', '2rem'],
    '::after': {
      content: "''",
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0rem',
      top: '0rem',
      background: 'linear-gradient(193deg, rgba(46, 59, 73, 0) 0%, rgba(49, 55, 62, 0) 64%, rgb(0, 0, 0) 100%)'
    },
    '.hero_content_container': {
      border: 'none'
    },
    '.gatsby-link': {
      alignSelf: 'flex-start'
    },
    '.ctaButton': {
      color: ['black', '', '', 'white'],
      border: 'solid 2px',
      borderColor: 'primary',
      backgroundColor: '#da333f',
      marginLeft: '1rem',
      ':hover': {
        backgroundColor: 'rgb(218,51,63,.5)',
        color: 'white'
      }
    },
    '.slick-slider': {
      height: ['100vh', '', '80vh'],
      '.slick-slide > div': {
        height: ['100vh', '', '80vh']
      },
      '.slick-prev, .slick-next': {
        display: 'none !important',
        color: 'white',
        bottom: '0rem',
        backgroundColor: 'primary',
        height: '30px',
        borderRadius: '100px',
        padding: '5px'
      },
      '.slick-slide img': {
        height: ['100vh', '', '80vh']
      }
    },
    '.muteToggle': {
      display: 'none'
    },
    '.hero_content_container': {
      // padding: ['2rem', '', '', '4.5rem', '5.5rem'],
      //   textShadow: "3px 1px 1px black",
      backgroundColor: 'rgba(255, 255, 255, .5)',
      width: ['100%', '', 'fit-content'],
      // marginLeft: ['', '', '', '4rem'],
      top: 'unset',
      margin: '0rem',
      bottom: '0rem',
      transform: 'unset',
      padding: '1.25rem',
      // display: 'none',
      '.title': {
        variant: 'customVariants.title',
        color: 'black',
        fontSize: ['2rem', '', '', '3rem'],
        '::after': {} // removes the star
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontSize: ['2rem', '', '3rem', '3rem'],
        borderBottom: 'solid 4px',
        color: 'white',
        width: 'fit-content',
        marginLeft: '1rem'
      },

      '.text': {
        variant: 'customVariants.text',
        color: 'light',
        marginBottom: 'unset',
        fontSize: ['1rem', '1.25rem']
      }
    },
    '.shoutWidgetContainer': {
      display: 'none'
    }
  },
  privateEvents: {
    '.private-events-container': {
      display: 'flex',
      justifyContent: 'center',
      '.content': {
        width: '80%',
        fontSize: '2rem',
        maxWidth: '1200px'
      }
    }
  }
}
